export interface TypographyStyle {
  fontWeight: string;
  fontSize: string;
  letterSpacing: string;
  lineHeight: string;
}

export interface Theme {
  typography: {
    [category: string]: {
      [weight: string]: TypographyStyle;
    };
  };
  colors: {
    [colorName: string]: string;
  };
}

export type TypographyType =
  | 'headlineLarge-bold'
  | 'headlineMedium-bold'
  | 'headlineSmall-bold'
  | 'titleLarge-bold'
  | 'titleLarge-semibold'
  | 'titleMedium-bold'
  | 'titleMedium-semibold'
  | 'titleMedium-medium'
  | 'titleSmall-bold'
  | 'titleSmall-semibold'
  | 'titleSmall-medium'
  | 'bodyLarge-bold'
  | 'bodyLarge-semibold'
  | 'bodyLarge-medium'
  | 'bodyMedium-bold'
  | 'bodyMedium-medium'
  | 'bodySmall-medium';

const theme : Theme = {
  typography: {
    headlineLarge: {
      bold: {
        fontWeight: 'bold',
        fontSize: '29px',
        letterSpacing: '0px',
        lineHeight: '40px',
      },
    },
    headlineMedium: {
      bold: {
        fontWeight: 'bold',
        fontSize: '26px',
        letterSpacing: '0px',
        lineHeight: '35px',
      },
    },
    headlineSmall: {
      bold: {
        fontWeight: 'bold',
        fontSize: '22px',
        letterSpacing: '0px',
        lineHeight: '30px',
      },
    },
    titleLarge: {
      bold: {
        fontWeight: 'bold',
        fontSize: '20px',
        letterSpacing: '0px',
        lineHeight: '29px',
      },
      semibold: {
        fontWeight: '600',
        fontSize: '20px',
        letterSpacing: '0px',
        lineHeight: '29px',
      },
    },
    titleMedium: {
      bold: {
        fontWeight: 'bold',
        fontSize: '18px',
        letterSpacing: '0px',
        lineHeight: '29px',
      },
      semibold: {
        fontWeight: '600',
        fontSize: '18px',
        letterSpacing: '0px',
        lineHeight: '29px',
      },
      medium: {
        fontWeight: '500',
        fontSize: '18px',
        letterSpacing: '0px',
        lineHeight: '29px',
      },
    },
    titleSmall: {
      bold: {
        fontWeight: 'bold',
        fontSize: '15px',
        letterSpacing: '0px',
        lineHeight: '23px',
      },
      semibold: {
        fontWeight: '600',
        fontSize: '15px',
        letterSpacing: '0px',
        lineHeight: '23px',
      },
      medium: {
        fontWeight: '500',
        fontSize: '15px',
        letterSpacing: '0px',
        lineHeight: '23px',
      },
    },
    bodyLarge: {
      bold: {
        fontWeight: 'bold',
        fontSize: '13px',
        letterSpacing: '0px',
        lineHeight: '18px',
      },
      semibold: {
        fontWeight: '600',
        fontSize: '13px',
        letterSpacing: '0px',
        lineHeight: '18px',
      },
      medium: {
        fontWeight: '500',
        fontSize: '13px',
        letterSpacing: '0px',
        lineHeight: '18px',
      },
    },
    bodyMedium: {
      bold: {
        fontWeight: 'bold',
        fontSize: '12px',
        letterSpacing: '0px',
        lineHeight: '20px',
      },
      medium: {
        fontWeight: '500',
        fontSize: '12px',
        letterSpacing: '0px',
        lineHeight: '20px',
      },
    },
    bodySmall: {
      medium: {
        fontWeight: '500',
        fontSize: '11px',
        letterSpacing: '0px',
        lineHeight: '16px',
      },
    },
  },
  colors: {
    blue30: '#007F8C',
    blue50: '#00D4EA',
    blue80: '#CCF6FB',
    neutral0: '#000000',
    neutral10: '#060606',
    neutral20: '#1E2530',
    neutral30: '#555D67',
    neutral40: '#838A93',
    neutral50: '#A5ACB8',
    neutral60: '#C0C4CD',
    neutral70: '#D6D9E1',
    neutral80: '#E5E8F0',
    neutral90: '#F4F5FB',
    neutral100: '#FFFFFF',
    green30: '#05884A',
    green50: '#09E27C',
    green80: '#C6F9E5',
    wine30: '#900535',
    wine50: '#C2185B',
    wine80: '#FFD9E2',
    orange30: '#B34A0D',
    orange50: '#FF6A13',
    orange80: '#FFE1D0',
    pink30: '#CC3D9E',
    pink50: '#FF4CC6',
    pink80: '#FFDBF4',
    yellow30: '#998413',
    yellow50: '#FFD620',
    yellow80: '#FFFB92',
    purple30: '#563396',
    purple50: '#8F55FA',
    purple80: '#E9DDFE',
    red30: '#992529',
    red50: '#FF3D45',
    red80: '#FFDBDA',
  },
};

export default theme;